import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import HtmlParser from "react-html-parser";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import StaticUrls from "../common-data/static-urls";
import { Link } from "gatsby";
import "./styles/OfficeListing.scss";

const OfficeListing = ({OfficeList}) => {
  return (
    <div className="office-listing" id="section-office-listings">
      <Container>        
      <Row>
      {OfficeList?.map((item) => {
        const TileImage = item?.node?.Tile_Image?.internal.description ? item?.node?.Tile_Image?.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
        
        return (
          <Col md={6} lg={4} key={item?.node.strapiId} className="office-listing-item">
            <div className="office-image spacing-bottom-24">
              <Link to={`${item?.node?.URL}/`}>
                <ImageRenderer
                  ImageSrc={{url:TileImage}} altText={item?.node?.Title} ggfx_results={item?.node?.ggfx_results} strapi_id={item?.node?.strapiId} imagename="offices.Tile_Image.commontile"
                />
              </Link> 
            </div>
            <div className="office-details">
              <h5 className="office-name text-22 bold"><Link to={`${item?.node?.URL}/`} className="text-22 bold"> {item?.node?.Title}</Link></h5>
              <p className="text-18">{HtmlParser(item?.node.Address)}</p>
            </div>
            <div className="office-contact">
              <p><a href={`tel:${item?.node.Phone_Number}`} className="phone">{item?.node.Phone_Number}</a> / <Link to={`${StaticUrls.GeneralEnquiry}?office=${item?.node?.URL}`}>Email</Link></p>
            </div>
         </Col>
          );
     })}
      </Row>
      </Container>
    </div>
  );
};

export default OfficeListing;