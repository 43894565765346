import React from "react";
import { Container} from "react-bootstrap";
import { useStaticQuery, graphql } from 'gatsby';
import HtmlParser from "react-html-parser";
import { MapMarkerInfoBox } from '../map/map-marker';
import LocRatingMap from '../map/loc-rating-map';
import StaticUrls from "../common-data/static-urls";
import "./styles/OfficeListingMap.scss";

const OfficeListingMap = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiOffices {
          edges {
            node {
              Title
              URL
              Address
              Latitude
              Longitude
            }
          }
      }
    }
 `);
    // Get Map Details
    let mapItems = [];
    data.allStrapiOffices.edges.forEach(({ node }, key) => {
        if (node.Title !== "Property Management") {
            let mapItem = {};
            mapItem['lat'] = node.Latitude;
            mapItem['lng'] = node.Longitude;
            const mapMarkerImageShowFlag = 0;
    
            let viewBtn = `<a class='mapviewbtn' target='_parent' style='float:left;width:100%;color: #fff;font-weight:bold;margin:15px 0;background: #5D9272;padding: 8px;border-radius: 4px;text-align: center;' href="${StaticUrls.OfficeLandingPage}${node.URL}">View Office</a>`;
            let officetitle = `<span style='color:#5D9272;font-weight:bold;display: inline-block;margin-bottom: 8px;'>${node.Title}</span><br>`;
            let mapItemHtml = MapMarkerInfoBox(officetitle + node.Address + viewBtn, mapMarkerImageShowFlag);
    
            mapItem['html'] = mapItemHtml;
            mapItems.push(mapItem);
        }
    });
    

  return (
    <div className="office-listing-map">
      <Container fluid className="p-0">
          <LocRatingMap data={mapItems} hidestations={true} autocenter={false}/>
      </Container> 
    </div>
  );
};

export default OfficeListingMap;