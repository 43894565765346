import React from 'react';
import { Link } from 'gatsby';
import useLinkHelper from '../common-data/LinkHelper';

const CTARenderer = ({ CTA1Label, CustomCTA1Link, eventCTA1Hash, CTA1Link, CTA2Label, CustomCTA2Link, eventCTA2Hash, CTA2Link, btn1class, btn2class }) => {
  // Always call useLinkHelper
  const processedCTA1Link = useLinkHelper(CTA1Link);
  const processedCTA2Link = useLinkHelper(CTA2Link);

  const cta1LinkUrl = CustomCTA1Link || processedCTA1Link;
  const cta2LinkUrl = CustomCTA2Link || processedCTA2Link;

  // Handle smooth scrolling for hash links
  const handleHashClick = (event, hash) => {
    event.preventDefault();
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element not found for hash: ${hash}`);
    }
  };

  const renderLink = (label, url, btnClass, hash) => {
    if (hash) {
      return (
        <li key={hash}>
          <a
            className={`btn ${btnClass}`}
            href={`#${hash}`}
            onClick={(e) => handleHashClick(e, `#${hash}`)}
          >
            {label}
          </a>
        </li>
      );
    } else if (url) {
      return (
        <li key={url}>
          <Link className={`btn ${btnClass}`} to={url}>
            {label}
          </Link>
        </li>
      );
    }
    return null;
  };

  return (
    <ul className="m-0">
      {renderLink(CTA1Label, cta1LinkUrl, btn1class, eventCTA1Hash)}
      {renderLink(CTA2Label, cta2LinkUrl, btn2class, eventCTA2Hash)}
    </ul>
  );
};

export default CTARenderer;