import React from 'react';
import { useQuery } from '@apollo/client';
import { Container } from "react-bootstrap";
import Slider from 'react-slick';
import gql from "graphql-tag";
import PropertyCard from './PropertyCard';
import "./RecentlyLet.scss";

const GET_PROPERTIES = gql`
  query Properties {
    properties(
      where: {
        status: "Let"
      },
      sort: "updatedAt:DESC",
      limit: 3
    ) {
      id
      title
      slug
      price
      display_address
      images
      search_type
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      publish
    }
  }
`;

const RecentlyLet = (props) => {
  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    fetchPolicy: 'cache-first', // Use cache-first fetch policy
  });
  
  const filteredProperties = data?.properties || [];

  const settings = {
    dots: true,
    arrows: false,
    infinite: filteredProperties.length > 3,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 3,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 2,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="recently-sold-module let">
        <Container>
        <div className="heading-section">
          <h3>{props.title || "Recently Let"}</h3>
        </div>        
        {!loading && !error && (
          <Slider {...settings}>
            {filteredProperties.map((property) => (
              <PropertyCard
                key={property.id} // add key prop for each PropertyCard
                id={property.id}
                title={property.display_address}
                price={property.price}
                image={property.images?.[0]}
                ggfx_results={property.ggfx_results}
                slug={property.slug}
                searchType={property?.search_type}
                bedroom={property.title}
              />
            ))}
          </Slider>
        )}
        </Container>
    </div>
  );
};

export default RecentlyLet;