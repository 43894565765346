import { useStaticQuery, graphql } from 'gatsby';

const getLinkUrl = (node, link) => {
    if (node.URL !== link) {
        return null;
    }

    if (node.main_parent && !node.sub_parent) {
        return `/${node.main_parent.URL}/${link}/`;
    }

    if (node.main_parent && node.sub_parent) {
        return `/${node.main_parent.URL}/${node.sub_parent.URL}/${link}/`;
    }

    if (!node.main_parent && !node.sub_parent) {
        return node.Secondary_URL ? node.Secondary_URL : `/${link}/`;
    }

    return null;
};

const useLinkHelper = (link) => {
    const data = useStaticQuery(graphql`
        query {
            allStrapiAllMenus {
                edges {
                    node {
                        id
                        Label
                        Show_In_Burger_Menu
                        URL
                        Secondary_URL
                        main_parent {
                            URL
                            Show_In_Burger_Menu
                            Label
                        }
                        sub_parent {
                            URL
                            Label
                        }
                    }
                }
            }
        }
    `);

    for (const { node } of data.allStrapiAllMenus.edges) {
        const url = getLinkUrl(node, link);
        if (url) {
            return url;
        }
    }

    return null;
};

export default useLinkHelper;