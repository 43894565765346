import React from 'react';
import Img from 'gatsby-image';

/**
 * Assets
 */
import './styles/_index.scss';

const ImgTextBox = ({image, children, alt}) => {
    return (
        <div className="img-text-box">
            <div className="image spacing-bottom-48">
                <Img fluid={image} alt={alt}/>
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    )
}

export default ImgTextBox
