/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import $ from 'jquery'
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby";
import {calculateMonthlyPayment} from "../calculator"
import { ArrowDownFlter } from '@components/icon/icon'
import Select from 'react-select';
// use project specific import here

// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {

  // percentage convert
  function percentage(a, b) {
    var c = (parseFloat(a) * parseFloat(b)) / 100
    return parseFloat(c)
  }

  const data = useStaticQuery(graphql`
        query {
        img3: file(relativePath: { eq: "calc-sidebar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
  `);

  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
    property
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props
  let propertyValue = property?.property
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyValue?.price)
  )
  const [deposit, setDeposit] = useState(prefix + numberFormat(percentage(propertyValue?.price, 20)))

  const [pagecalc, setPagecalc] = useState(props?.propertyDetails?true:false)
  const [duration, setDuration] = useState({label:loadDuration + ' Years', value:loadDuration})
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState('')
  const [result, setResult] = useState("")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    } else {
      setLoan(prefix + 0)
    }
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value)
    setDeposit(prefix + numberFormat(d))

    if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
      let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
      setLoan(prefix + numberFormat(loan2))
    } else {
      setLoan(prefix + 0)
    }
  }

  const handleDuration = (event) => {
    //setDuration(filterNumber(event.target.value))
    //setDuration(filterNumber(event))
    setDuration({label:event + ' Years', value:event});
  
  }

  const handleInterest = event => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const doCalculate = () => {
    let loan =
    parseInt(filterNumber(purchasePrice)) -
    parseInt(filterNumber(deposit))
    setLoan(prefix + numberFormat(loan))
    getResult(interest, duration.value, loan)
    //console.log('duration',parseInt(filterNumber(purchasePrice)),interest,parseInt(filterNumber(deposit)),duration.value)
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration.value
      )
    );
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    var pval = Number($("input[name=purchase_price]").val().replace(/[^\d.-]/g, ''));
    var dval = Number($("input[name=deposit_available]").val().replace(/[^\d.-]/g, ''));
    if(dval > pval) {
      alert("Please enter deposit value less than property value");
      event.preventDefault()
      event.stopPropagation()
    }
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        doCalculate()
      }
    }
  }

  const ArrowDownFlters = () => (
    <div className="down-indicator">
        <ArrowDownFlter />
    </div>
  )

  useEffect(() => {
    doCalculate()
  }, [])
  
  useEffect(() => {

    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration.value, loan)
    }
    if(pagecalc===true) {
    doCalculate()
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  // DO NOT DO ANY CHNAGES - END

  

  var monthly_payment = Math.round(monthlyPayment);
  //console.log('aaaaaaaaaaaaaa', numberFormat(monthly_payment));

  return (
    
    <div className='static-text-columns calcpage mot-calc'>
    {!props.propertyDetails ? <Row>
    <Col lg={6}>
      <div className="left-img-calc">
        <Img fluid={data.img3.childImageSharp.fluid} alt="Estats Reviews - Anthony Pepe" />
      </div>
    </Col>
    <Col lg={6} className="form-calc-right">
    <div className="form mortgate-form stamp-duty-form">
    <div className="text-columns">
    <div className='container'>      {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Form.Group controlId="purchase_price">
          <Form.Label>Property Value</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="purchase_price"
            value={purchasePrice}
            placeholder="Purchase Price"
            onChange={handlePrice}
          />
        </Form.Group>
        <Form.Group controlId="deposit">
          <Form.Label>Deposit</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="deposit_available"
            value={deposit}
            placeholder="Deposit Available"
            onChange={handleDeposit}
          />
        </Form.Group>
        <Form.Group controlId="duration select-group">
          <Form.Label>Duration</Form.Label>
          <Select             
              options={[
              //{ value: "", label: "All Properties" },
              { value: "10", label: "10 Years" },
              { value: "15", label: "15 Years" },
              { value: "20", label: "20 Years" },
              { value: "25", label: "25 Years" },
              { value: "30", label: "30 Years" },
              { value: "35", label: "35 Years" },
              ]}
            classNamePrefix={"property-dropdown"}
            name="duration"
            value={duration}
            //defaultMenuIsOpen = {true}
           // placeholder="Duration (Years)"
            //onChange={handleDuration}
            onChange={(e) => {handleDuration(e.value, e.label)}}
            //onChange={(e) => {refine(e.value, e.label)}}
            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
              />             


          {/*<Form.Control
            as="select"
            className="form-control"
            required
            name="duration"
            value={duration}
            placeholder="Duration (Years)"
            onChange={handleDuration}
          >
            {durationOptions.map((value, key) => {
              return (
                <option value={value} key={key}>
                  {value} Years
                </option>
              )
            })}          
          </Form.Control>*/}
          
        </Form.Group>
        <Form.Group controlId="interest_rate">
          <Form.Label>Interest Rate (%)</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="interest_rate"
            value={interest}
            placeholder="Interest Rate (%)"
            onChange={handleInterest}
          />
        </Form.Group>
        <Form.Group controlId="loan_amount" className="d-none">
          <Form.Label>Loan Amount</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="loan_amount"
            value={loan}
            placeholder="Loan Amount"
            onChange={handleLoan}
            disabled="disabled"
          />
        </Form.Group>
        <Button type="submit" className="btn btn-pink btncalc">
          Calculate
        </Button>
      </Form>
      {result>0 && (
        <div className="alert-success">
          <p>
            Monthly Repayment: {currency}
            {numberFormat(monthly_payment)}
          </p>
        </div>
      )}
    </div>
        </div>

    </div>
    </Col>
    </Row>
 :
      <div className="form mortgate-form stamp-duty-form mort-form">
        <div className="text-columns">   
          {!showerror || result ? "" : (
            <div className="alert-error">
              <p>Highlighted fields are required</p>
            </div>
          )}
          <Form
            name="MortgageCalc"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            <div className="d-lg-flex d-md-block position-relative"> 
              <Form.Group controlId="purchase_price" className="mr-lg-5 first-group">
              <label className="plclabel callabel text-18 bold">{"Property Price (£)"}</label>
                <Form.Control
                  className="form-control size text-20-16"
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Purchase Price"
                  onChange={handlePrice}
                />
               
              </Form.Group>
              <Form.Group controlId="deposit" className="deposit position-relative">
              <label className="plclabel callabel text-18 bold">{"Deposit"}</label>
                <Form.Control
                  className="form-control size text-20-16"
                  required
                  type="text"
                  name="deposit_available"
                  value={deposit}
                  placeholder="Deposit Available"
                  onChange={handleDeposit}
                />                
              </Form.Group>
            </div>
            <div className="d-lg-flex d-md-block second-sec position-relative"> 
              <Form.Group controlId="interest_rate" className="mr-lg-5 second-group">
              <label className="plclabel callabel text-18 bold">{"Interest Rate (%)"}</label>
                <Form.Control
                  className="form-control size text-20-16"
                  required
                  type="text"
                  name="interest_rate"
                  value={interest}
                  placeholder="Interest Rate (%)"
                  onChange={handleInterest}
                />                
              </Form.Group>
              <Form.Group controlId="duration" className="select-group position-relative">
              <label className="plclabel callabel duration_label text-18 bold">{"Duration (Years)"}</label>

             <Select             
              options={[
              //{ value: "", label: "All Properties" },
              { value: "10", label: "10 Years" },
              { value: "15", label: "15 Years" },
              { value: "20", label: "20 Years" },
              { value: "25", label: "25 Years" },
              { value: "30", label: "30 Years" },
              { value: "35", label: "35 Years" },
              ]}
            classNamePrefix={"property-dropdown"}
            name="duration"
            value={duration}
            //defaultMenuIsOpen = {true}
           // placeholder="Duration (Years)"
            //onChange={handleDuration}
            onChange={(e) => {handleDuration(e.value, e.label)}}
            //onChange={(e) => {refine(e.value, e.label)}}
            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
              />             

              </Form.Group>
            </div>
            {/*<div className="d-lg-flex d-md-block position-relative">
              <Form.Group controlId="loan_amount" className="mr-lg-5 third-group">
              <label className="plclabel callabel text-18 bold">{"Loan Amount"}</label>
                <Form.Control
                  className="form-control size text-20-16"
                  required
                  type="text"
                  name="loan_amount"
                  value={loan}
                  placeholder="Loan Amount"
                  onChange={handleLoan}
                />               
              </Form.Group>
              <Form.Group controlId="loan_amount" className="position-relative third-group">
              <label className="plclabel callabel text-18 bold">&nbsp;</label>
              <Button type="submit" className="btn btn-pink btncalc butt-group stamp_butn text-20">               
                Calculate
              </Button>
              </Form.Group>
            </div>*/}
          </Form>
            <div className="alert-success">
              <p>
                <p className="text-18 bold amount-head">Estimated Monthly Mortgage Payment</p>
                <h3>{currency}{numberFormat(monthly_payment ? monthly_payment : 0)}</h3>
              </p>
            </div>
              <div className="mortgage-bottom-sec text-extra-small">
                <ul>
                  <li className="text-extra-small">The monthy payment is shown as a guide only.</li>
                  <li className="text-extra-small">The actual amount you will have to pay each month could be more or less than this figure.</li>
                </ul>
              </div>
        </div>
      </div>
      }
    </div>

  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
