/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat } from "./util"
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from "gatsby";
import Select from 'react-select';
import { ArrowDownFlter } from '@components/icon/icon'
import {
  calculate,
  countries,
  // propertyTypes,
  // buyerTypes,
} from "@starberry/react-calculator-stampduty"

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs
const propertyTypes = [
  {
    value: "residential",
    label: "Residential",
  },
  
]

const buyerTypes = [
  {
    value: "first",
    label: "First Time Buyer",
  },
  {
    value: "home",
    label: "Home Mover (main residence and not a first time buyer)",
  },
  {
    value: "investor",
    label: "Second Homes / BTL",
  },
]

const checkList = [
  {
    value: "first",
    label: "I’m buying my first home or next home",
  },
  {
    value: "investor",
    label: "I’m buying an additional property or second home",
  },
]

// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = props => {
  const data = useStaticQuery(graphql`
  query {
  img3: file(relativePath: { eq: "calc-sidebar.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
          }
      }
  }
  }
`);

  const currency = props.currency

  let propertyDetailValue = props?.property?.property
  const [result, setResult] = useState(false)
  //const [defaultBuyer, setDefaultBuyer] = useState({label:checkList[0].label, value:checkList[0].value})
  const [propertyType, setPropertyType] = useState(props.propertyType)
  const [propertyValue, setPropertyValue] = useState(
    currency + numberFormat(propertyDetailValue?.price)
  )
  //const [buyer, setBuyer] = useState(props.buyerType)
  const [buyer, setBuyer] = useState(props.buyerType==="first"?{label:checkList[0].label, value:checkList[0].value}:{label:checkList[1].label, value:checkList[1].value})

  const formatPrice = str => {
    return currency + str.toLocaleString("en-US")
  }

  const [pagecalc, setPagecalc] = useState(props?.propertyDetails?true:false)

  const doCalculate = () => {
    const results = calculate(
      filterNumber(propertyValue),
      propertyType,
      countries.ENGLAND,
      buyer.value
    )

    // effective stamp duty rates
    // Formula => ((tax / price) * 100).
    // Ex: (213750/2500000) * 100 = 8.55%.
    let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
    effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)
    let summaryBands = []
    results.summaryBands.map(result => {
      summaryBands.push(
        result.adjustedRate +
          "% between " +
          formatPrice(result.start) +
          " and " +
          formatPrice(result.end)
      )
    })
    const result = {
      effectiveRate: effectiveRate,
      summaryBands: summaryBands,
      tax: formatPrice(results.tax),
    }

    setResult(result)
  }

  useEffect(() => {
    if(pagecalc===true) {
      doCalculate()
    }
  }, [buyer, propertyValue])

  useEffect(() => {
    doCalculate()
  }, [])

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    doCalculate()
  }

  const handleDeposit = event => {
    setPropertyValue(currency + numberFormat(filterNumber(event.target.value)))
  }

  const handlePropertyType = event => {
    setPropertyType(event.target.value)
  }
  const ArrowDownFlters = () => (
    <div className="down-indicator">
        <ArrowDownFlter />
    </div>
  )

  const handleBuyer = event => {
    setBuyer(event.target.value)
  }

  const handleBuyers = event => {
    //console.log("event", event)
    setBuyer({label:event.label, value:event.value})
  }


  // DO NOT DO ANY CHNAGES - END

  return (
    <div className='static-text-columns calc-stamp-duty calcpage'>
        {!props.propertyDetails ? <Row>
    <Col lg={6}>
      <div className="left-img-calc">
        <Img fluid={data.img3.childImageSharp.fluid} alt="Estats Reviews - Anthony Pepe" />
      </div>
    </Col>
    <Col lg={6} className="form-calc-right">
    <div className="form mortgate-form stamp-duty-form">
    <div className="text-columns">
    <div className='container'>
      <Form
        name="MortgageCalc"
        noValidate
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Form.Row>
          <Form.Group controlId="property_type" className="radio-wrap calc-stamp-duty-property_type">
            {propertyTypes.map((item, i) => {
              return (
                <Form.Check
                  key={i}
                  type="radio"
                  name="property_type"
                  id={`default-radio-${i}`}
                  value={item.value}
                  label={item.label}
                  checked={propertyType === item.value}
                  onChange={handlePropertyType}
                />
              )
            })}
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="deposit">
          <Form.Label>Deposit Available</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="deposit"
            value={propertyValue}
            placeholder="Purchase Price"
            onChange={handleDeposit}
          />
        </Form.Group>
      
          <Form.Group controlId="buyer" className="select-group">
            {/*buyerTypes.map((item, i) => {
              return (
                <Form.Check
                  key={i}
                  type="radio"
                  name="buyer"
                  id={`default-buyer-${i}`}
                  value={item.value}
                  label={item.label}
                  checked={buyer === item.value}
                  onChange={handleBuyer}
                />
              )
            })*/}
              <Select             
                options={buyerTypes}
                classNamePrefix={"property-dropdown"}
                name="buyer"
                value={buyer}
                //defaultMenuIsOpen = {true}
                // placeholder="Duration (Years)"
                //onChange={handleDuration}
                onChange={(e) => {handleBuyers(e)}}
                components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
                />
          </Form.Group>

        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
      </Form>
      {result && (
        <div className="alert-success">
          <div>
            <h3 className="h5">
              Stamp Duty: <strong>{result.tax}</strong>
            </h3>
            <p>
              <span className="result-head">You have to pay stamp duty : </span>
              {result.summaryBands.map((sm, i) => {
                return <span key={i}>{sm}</span>
              })}
              <span>Your effective stamp duty rate is {isNaN(result.effectiveRate)?"0%":result.effectiveRate+"%"}</span>
            </p>
          </div>
        </div>
      )}
    </div>
    </div>
   </div>
</Col>
</Row>
: 
    <div className="form mortgate-form stamp-duty-form">
      <div className="text-columns">
        <Form
          name="MortgageCalc"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="stamp_calcs">
          <div className="d-lg-flex d-md-block position-relative">
             <Form.Group controlId="buyer" className="select-group mr-lg-5 position-relative">
                <label className="plclabel callabel text-18 bold">I am...</label>
                <Select             
                options={checkList}
                classNamePrefix={"property-dropdown"}
                name="buyer"
                value={buyer}
                //defaultMenuIsOpen={true}
                //defaultMenuIsOpen = {true}
                // placeholder="Duration (Years)"
                //onChange={handleDuration}
                onChange={(e) => {handleBuyers(e)}}
                components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
                />
              </Form.Group> 

              <Form.Group controlId="deposit position-relative" className="first-group">
              <label className="plclabel callabel text-18 bold">{"Property Price (£)"}</label>
                <Form.Control
                  className="form-control size"
                  required
                  type="text"
                  name="deposit"
                  value={propertyValue}
                  placeholder="Property Price (£)"
                  onChange={handleDeposit}
                />               
              </Form.Group>                           
           </div>
           {/*<div className="d-lg-flex d-md-block position-relative"> 
            <Form.Group className="mr-lg-5 first-group">              
                <Button type="submit" className="btn mort_btn butt-group">
                  Calculate
                </Button>
              </Form.Group>
              <Form.Group controlId="loan_amount" className="position-relative third-group">
                </Form.Group>
            </div>*/}
          </div>
        </Form>
          <div className="alert-success">
            <div>
              <p className="text-18 bold amount-head">Your stamp duty will be: </p>
              <h3>{result?.tax ? result?.tax : "£0"}</h3>
              <p className="result-head-content">
                <p className="result-head text-extra-small">You have to pay stamp duty : </p>
                {result?.summaryBands && result?.summaryBands.length > 0 && result?.summaryBands.map((sm, i) => {
                  return <span key={i}>{sm}</span>
                })}
                <span>Your effective stamp duty rate is {isNaN(result?.effectiveRate)?"0%":result?.effectiveRate + "%"}</span>
              </p>
            </div>
          </div>
      </div>
    </div>
}
        </div>

  )
}

StampdutyCalc.defaultProps = {
  propertyType: `residential`,
  propertyValue: `0`,
  buyerType: `first`,
  currency: process.env.CURRENCY ? process.env.CURRENCY : "£",
}

StampdutyCalc.propTypes = {
  propertyType: PropTypes.string,
  propertyValue: PropTypes.any,
  buyerType: PropTypes.string,
  currency: PropTypes.string,
}

export default StampdutyCalc
