import React from "react";
import ReviewsModule from "../../Reviews/ReviewsModule";
import { getSpacingClass } from "../../common-data/common-data";
import OntheMarket from "../../PropertyCarousel/OntheMarket";
import RecentlySold from "../../PropertyCarousel/RecentlySold";
import RecentlyLet from "../../PropertyCarousel/RecentlyLet";
import OfficeListing from "../../office-listing/OfficeListing";
import OfficeListingMap from "../../office-listing/OfficeListingMap";
import TeamSlider from "../../static-person-video/static-person-video";
import JobPositions from "../../static-position/static-position";
import './ModuleSelectModule.scss';

const ModuleSelectModule = ({ ModuleData, ...PageData }) => {

  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
   
  return (
    <div className={`module-select-module ${spacingClass}`}>
      {ModuleData?.Select_Module === "Reviews" &&
        <ReviewsModule reviewsData={PageData?.ReviewsData}/>
      }
      {ModuleData?.Select_Module === "On_the_market" &&
        <OntheMarket title={ModuleData?.Select_Module_Title} />
      }
      {ModuleData?.Select_Module === "Recently_Sold" &&
        <RecentlySold title={ModuleData?.Select_Module_Title} />
      }
      {ModuleData?.Select_Module === "Recently_Let" &&
        <RecentlyLet title={ModuleData?.Select_Module_Title} />
      }
      {ModuleData?.Select_Module === "Office_Landing_Map" &&
        <OfficeListingMap  />
      }
      {ModuleData?.Select_Module === "Office_Listing" &&
        <OfficeListing OfficeList={PageData?.OfficeList?.edges} />        
      }
      {ModuleData?.Select_Module === "Team_Slider" &&
        <TeamSlider data={PageData?.TeamSlider} />
      }
      {ModuleData.Select_Module == "Current_Positions" &&
        <JobPositions data={PageData?.JobOpenings} />
      }
    </div>
  );
};

export default ModuleSelectModule;