import React from "react";
import StampDutyCalc from "../../calculators/stampdutycalc/index";
import MortgageCalc from "../../calculators/mortgagecalc/index";
import RentYield from "../../calculators/rentalyieldcalc/index";
import { getSpacingClass } from "../../common-data/common-data";
import "./CalculatorsModule.scss";

const CalculatorsModule = ({ ModuleData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  return (
    <div className={`calculators-module ${spacingClass}`}>
        {ModuleData.Select_Calculator === 'Stampduty_Calculator' &&
            <StampDutyCalc />
        }
        {ModuleData.Select_Calculator === 'Mortgage_Calculator' &&
            <MortgageCalc />
        }
        {ModuleData.Select_Calculator === 'Rent_to_Yield_Calculator' &&
            <RentYield />
        }
    </div>
  );
};

export default CalculatorsModule;