import React from "react";
import ElfsightWidget from "../../Elfsight/ElfsightWidget";
import "./ElfsightInstaWidgetModule";

const ElfsightInstaWidgetModule = ({ ModuleData }) => {
  return (
    <div className="elfsight-insta-widget-module">
      <ElfsightWidget widgetID={ModuleData?.Widget_Code} />
    </div>
  );
};

export default ElfsightInstaWidgetModule;