import React from "react";
import { getSpacingClass } from "../../common-data/common-data";
import HomeBoxes from "@components/home-boxes/home-boxes";

import './TileBlocksModule.scss';

const TileBlocksModule = ({ ModuleData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  return (
    <div className={`tile-blocks-module ${spacingClass}`}>
      <HomeBoxes data={ModuleData?.Tiles}  />
    </div>
  );
};

export default TileBlocksModule;