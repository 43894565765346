import React from "react";
import {Container} from "react-bootstrap";
import HtmlParser from 'react-html-parser';

import { getSpacingClass } from "../../common-data/common-data";
import "./TableModule.scss";

const TableModule = ({ ModuleData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  return (
    <div className={`table-module ${spacingClass}`}>
      <Container>
       <div className="table-content-wrap">
           {ModuleData?.Table_Content && HtmlParser(ModuleData.Table_Content)}
        </div>      
      </Container> 
    </div>
  );
};

export default TableModule;