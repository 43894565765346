import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'gatsby';
import {Container} from "react-bootstrap";
import Slider from 'react-slick';
import gql from "graphql-tag";
import StaticUrls from "../common-data/static-urls";
import PropertyCard from './PropertyCard';
import "./OntheMarket.scss";

const GET_PROPERTIES = gql`
query Properties($search_type: String!) {
    properties(
      where: {
        search_type: $search_type ,
        publish:true
      },
        sort:"createdAt:DESC",
        limit: 3
    ) {
        id
        title
        slug
        price
        display_address
        images
        search_type
        ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
        }
        publish
    }
  }
`;

const OntheMarket = (props) => {
  const [activeTab, setActiveTab] = useState('For Sale');

  const mapTabToStatus = {
    'For Sale': 'sales',
    'To Rent': 'lettings',
  };

  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: { search_type: mapTabToStatus[activeTab] },
    fetchPolicy: 'cache-first',
  });

  // Ensure filteredProperties is always an array
  const filteredProperties = data?.properties || [];

  const settings = {
    dots: true,
    arrows: false,
    infinite: filteredProperties.length > 3 ? true : false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 2 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: filteredProperties.length > 1 ? true : false,
          dots: true,
        },
      },
    ],
  };

  const handleTabClick = tab => {
    setActiveTab(tab);
  };

  return (
    <div className="onthemarket-module">
        <Container>
          <div className="module-content">
          <div className="heading-section ">
            <h3 className="spacing-bottom-24">{props.title || "On the market"}</h3>                
            <div className="prop-tab-list">
              <button className={`btn-sale ${activeTab === 'For Sale' ? 'active' : ''}`} onClick={() => handleTabClick('For Sale')}>For Sale</button>
              <button className={activeTab === 'To Rent' ? 'active' : ''} onClick={() => handleTabClick('To Rent')}>To Rent</button>
            </div>
          </div>                      
          <div className="cta-link-wrapper">
              <Link to={activeTab === 'For Sale'  ?  StaticUrls.ForSale : StaticUrls.ToRent } className="btn btn-transparent-black">More Properties</Link>
          </div>
          <div className="module-slider">                
          {!loading && !error && (
              <Slider {...settings}>
                {filteredProperties.map((property) => (
                    <PropertyCard id={property?.id} title={property?.display_address} price={property?.price} image={property?.images?.[0]} ggfx_results={property?.ggfx_results} searchType={property?.search_type} slug={property?.slug} bedroom={property?.title} />
                ))}
              </Slider>
              
          )}  
          </div>      
            
          </div>
        </Container>
    </div>
  );
};

export default OntheMarket;